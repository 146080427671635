import cx from 'classnames';
import React from 'react';

import { twMerge } from 'tailwind-merge';
import { Icon } from 'components/Icon';
import { BaseButton, BaseProps as BaseButtonProps } from '../Base';
import { PolymorphicComponentProps } from 'types/component-types';

export type BaseProps = {
  variant?: 'ghost' | 'primary' | 'secondary' | 'link' | 'navItem';
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  loading?: boolean;
  loadingText?: string;
} & BaseButtonProps;

export type Props<C extends React.ElementType> = PolymorphicComponentProps<C, BaseProps>;

export const Button = React.forwardRef(function ButtonWithRef<C extends React.ElementType = 'button'>(
  {
    className,
    children,
    disabled,
    variant = 'primary',
    type = 'button',
    loading = false,
    startAdornment,
    endAdornment,
    loadingText,
    ...props
  }: Props<C>,
  ref: React.ForwardedRef<Element>,
): JSX.Element {
  const buttonClasses = twMerge(
    cx(
      'border border-transparent font-semibold  disabled:cursor-default font-dazzed',
      {
        'bg-purple-200 hover:bg-purple-300 active:bg-purple-400 border-black-12% disabled:bg-black-12% disabled:text-black-38%':
          variant === 'primary',

        'bg-transparent hover:bg-black-8% active:bg-black-12% border-black-100% disabled:bg-transparent disabled:text-black-38% disabled:border-black-38%':
          variant === 'secondary',

        'underline hover:bg-black-4% active:bg-black-12% disabled:text-black-38% disabled:hover:bg-transparent':
          variant === 'ghost',
        'transition-colors': !disabled,
        'underline py-6 px-4 hover:no-underline leading-5': variant === 'link',
        'py-6 px-4 hover:underline': variant === 'navItem',
      },
      className,
    ),
  );

  return (
    <BaseButton {...props} ref={ref} type={type} className={buttonClasses} disabled={disabled || loading}>
      {loading && (
        <span className="flex items-center justify-center grid-area-overlap animate-loader-enter">
          <Icon name="Spinner" title={loadingText ? undefined : 'loading'} className="animate-spin-step" />
          {loadingText && <span className="ml-12">{loadingText}</span>}
        </span>
      )}

      <span
        aria-hidden={loading ? 'true' : 'false'}
        className={cx('flex items-center justify-center grid-area-overlap', {
          'opacity-0 transition-all': loading,
        })}
      >
        {startAdornment}
        {children}
        {endAdornment}
      </span>
    </BaseButton>
  );
});
