import cx from 'classnames';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { PolymorphicComponentProps } from 'types/component-types';

/**
 * The `as` prop can be used if a design calls for a heading that's
 * styled differently than the underlying rendered heading tag
 */
export type HeadingElements = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'h8' | 'h9';

type HeadingProps = {
  /**
   * The `level` determines the style associated with the heading
   */
  level?: HeadingElements;
  className?: string;
  children?: React.ReactNode;
  useDefaultMargins?: boolean;
};

export type Props<C extends React.ElementType> = PolymorphicComponentProps<C, HeadingProps>;

const headingClasses = {
  h1: 'font-dazzed md:text-lh1 text-lh1-sm font-bold',
  h2: 'font-dazzed md:text-lh2 text-lh2-sm font-bold',
  h3: 'font-dazzed md:text-lh3 text-lh3-sm font-bold',
  h4: 'font-dazzed md:text-lh4 text-lh4-sm font-bold',
  h5: 'font-dazzed md:text-lh5 text-lh5-sm font-bold',
  h6: 'font-dazzed md:text-lh6 text-lh6-sm font-semibold uppercase tracking-widest',
  h7: 'font-dazzed md:text-lh7 text-lh7-sm font-medium',
  h8: 'font-dazzed md:text-lh8 text-lh8-sm font-medium',
  h9: 'font-dazzed md:text-lh9 text-lh9-sm font-medium',
};

// @TODO(juan): these are still not confirmed with kim
const defaultMarginClasses = {
  h1: 'mb-16',
  h2: 'mb-16',
  h3: 'mb-16',
  h4: 'mb-16',
  h5: 'mb-16',
  h6: 'mb-16',
  h7: 'mb-16',
  h8: 'mb-16',
  h9: 'mb-16',
};

export const Heading = <C extends React.ElementType = 'h1'>({
  level = 'h1',
  as,
  className,
  children,
  useDefaultMargins,
  ...props
}: Props<C>): JSX.Element => {
  const asElement = as || (['h7', 'h8', 'h9'].includes(level) ? 'h6' : level);

  return React.createElement(
    asElement,
    {
      className: twMerge(
        cx(
          'font-dazzed',
          headingClasses[level],
          {
            [defaultMarginClasses[level]]: useDefaultMargins, // @TODO(juan): these are still not confirmed with kim
          },
          className,
        ),
      ),
      ...props,
    },
    children,
  );
};
